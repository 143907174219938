<template>
    <v-container>
        <uploadFile></uploadFile>
    </v-container>
</template>
<script>
import uploadFile from  '@/components/manipulacionArchivos/uploadFile.vue';


export default {

components :{
    uploadFile
}

}
</script>
    
